import React from 'react';

const TabBar = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { name: 'Map', icon: '🗺️', id: 'map' },
    { name: 'Submit a pint', icon: '🍺', id: 'form' },
    { name: 'Recent pints', icon: '📄', id: 'recents' },
    { name: 'Stats', icon: '📈', id: 'charts' },
  ];

  return (
    <div className="fixed bottom-4 lg:bottom-auto lg:top-10 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md lg:w-auto px-1 py-1 bg-white/70 backdrop-blur-md border border-gray-200 dark:bg-gray-700/60 dark:border-gray-700 rounded-lg">
      <div className="flex justify-around items-center space-x-1">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            type="button"
            onClick={() => setActiveTab(tab.id)}
            className={`inline-flex flex-col items-center justify-center px-4 py-1 transition-all duration-300 ease-in-out ${
              activeTab === tab.id ? 'text-green-200 bg-gray-300/50 dark:bg-gray-500 rounded-md' : 'text-gray-500 dark:text-white'
            }`}
          >
            <span className={`material-icons mb-1 ${activeTab === tab.id ? 'text-gray-700 dark:text-white' : 'text-white dark:text-white'}`}>
              {tab.icon}
            </span>
            <span className={`text-xs font-semibold ${activeTab === tab.id ? 'text-gray-700 dark:text-white' : 'text-gray-500 dark:text-white'}`}>
              {tab.name}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabBar;