import React, { useEffect, useState, useCallback } from 'react';
import { getDatabase, ref, get } from 'firebase/database';
import { format, subMonths, startOfMonth } from 'date-fns';

const PintStatsOverview = () => {
  const [stats, setStats] = useState({
    averagePrice: 0,
    totalPints: 0,
    brandBreakdown: {},
    monthlyTrend: null,
    insights: []
  });
  const [currentInsightIndex, setCurrentInsightIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentInsightIndex(current => 
        (current + 1) % stats.insights.length
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [stats.insights.length]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generateInsights = (data) => {
    const {
      priceChange,
      mostReportedBeer,
      currentAverage,
      happyHourPercentage,
      totalPints,
      brandCount,
      averageContributionsPerPub
    } = data;

    return [
      `Reported pint prices have ${Math.abs(priceChange) < 1 ? 'stabilized' : 
        priceChange > 0 ? 'risen' : 'fallen'} by ${Math.abs(priceChange).toFixed(1)}% in the last month`,
      `${mostReportedBeer[0]} is the most reported beer with ${mostReportedBeer[1]} contributions`,
      `The current average price of £${currentAverage.toFixed(2)} is ${
        currentAverage > 4.50 ? 'above' : 'below'
      } the typical UK pub average of £4.50`,
      `${(happyHourPercentage * 100).toFixed(1)}% of reported pints were during happy hour`,
      `On average, each pub has ${averageContributionsPerPub.toFixed(1)} pint contributions`,
      `Users have reported ${brandCount} different beer brands across ${totalPints} submissions`
    ];
  };

  useEffect(() => {
    const fetchStats = async () => {
      const db = getDatabase();
      const pricesRef = ref(db, 'beerPrices');
      
      try {
        const snapshot = await get(pricesRef);
        const data = snapshot.val();
        
        if (data) {
          const pints = Object.values(data);
          const currentDate = new Date();
          const lastMonthDate = subMonths(currentDate, 1);
          
          // Calculate various statistics
          const totalPrice = pints.reduce((sum, pint) => sum + pint.price, 0);
          const averagePrice = totalPrice / pints.length;
          const happyHourPints = pints.filter(pint => pint.happyHour).length;
          const happyHourPercentage = happyHourPints / pints.length;
          const uniquePubs = new Set(pints.map(pint => pint.pub)).size;
          const averageContributionsPerPub = pints.length / uniquePubs;

          // Brand breakdown
          const brandBreakdown = pints.reduce((acc, pint) => {
            acc[pint.beerType] = (acc[pint.beerType] || 0) + 1;
            return acc;
          }, {});

          // Monthly trends calculation
          const thisMonthPints = pints.filter(pint => 
            new Date(pint.timestamp) >= lastMonthDate
          );

          const lastMonthAverage = thisMonthPints.reduce((sum, pint) => 
            sum + pint.price, 0) / thisMonthPints.length;

          const previousMonthPints = pints.filter(pint => {
            const pintDate = new Date(pint.timestamp);
            return pintDate >= subMonths(lastMonthDate, 1) && pintDate < lastMonthDate;
          });

          const previousMonthAverage = previousMonthPints.reduce((sum, pint) => 
            sum + pint.price, 0) / previousMonthPints.length;

          const priceChange = ((lastMonthAverage - previousMonthAverage) / previousMonthAverage) * 100;
          const mostReportedBeer = Object.entries(brandBreakdown)
            .sort(([,a], [,b]) => b - a)[0];

          const insights = generateInsights({
            priceChange,
            mostReportedBeer,
            currentAverage: averagePrice,
            happyHourPercentage,
            totalPints: pints.length,
            brandCount: Object.keys(brandBreakdown).length,
            averageContributionsPerPub
          });

          setStats({
            averagePrice,
            totalPints: pints.length,
            brandBreakdown,
            monthlyTrend: {
              previousMonth: previousMonthAverage,
              currentMonth: lastMonthAverage,
              percentageChange: priceChange
            },
            insights
          });
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="space-y-6 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-semibold mb-2 dark:text-white">Average Pint Price</h3>
          <p className="text-3xl font-bold text-green-600 dark:text-green-400">
            £{stats.averagePrice.toFixed(2)}
          </p>
        </div>
        
        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-semibold mb-2 dark:text-white">Total Contributions</h3>
          <p className="text-3xl font-bold text-green-600 dark:text-green-400">
            {stats.totalPints}
          </p>
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-semibold mb-2 dark:text-white">Monthly snapshots ✨</h3>
          <p className="text-lg dark:text-gray-300 transition-opacity duration-500 transition-transform">
            {stats.insights[currentInsightIndex] || 'Loading...'}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PintStatsOverview;