import React, { useState, useCallback, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { getDatabase, ref, get, set, query, orderByChild, startAt, endAt } from 'firebase/database';
import { useTheme } from '../utils/ThemeContext';

const BeerTypeSelect = ({ selectedBeerType, onChange, onPintSubmitted }) => {
  const [inputValue, setInputValue] = useState('');
  const [defaultOptions, setDefaultOptions] = useState([]);
  const { isDark } = useTheme();
  const database = getDatabase();

  // Helper function to sort beer options
  const sortBeersByCount = (beers) => {
    return beers.sort((a, b) => {
      if (a.__isNew__) return -1;
      if (b.__isNew__) return 1;
      // Sort by count first
      const countDiff = (b.count || 0) - (a.count || 0);
      // If counts are equal, sort alphabetically
      return countDiff !== 0 ? countDiff : a.label.localeCompare(b.label);
    });
  };

  useEffect(() => {
    const loadInitialOptions = async () => {
      try {
        // Define default beers in desired order
        const defaultBeerOrder = ['Guinness', 'Amstel', 'Camden Hells', 'Strongbow', 'Carling', 'Fosters', 'Carlsberg', 'Tennents', 'Magners', 'Kopparberg', 'Peroni', 'Birra Moretti', 'Peroni', 'Peroni,'];
        const beerTypesRef = ref(database, 'beerTypes');
        const snapshot = await get(beerTypesRef);
        
        if (snapshot.exists()) {
          const allBeers = Object.values(snapshot.val());
          // Filter and sort according to defaultBeerOrder
          const defaultBeers = defaultBeerOrder
            .map(beerName => 
              allBeers.find(beer => 
                beer.value.toLowerCase() === beerName.toLowerCase()
              )
            )
            .filter(beer => beer) // Remove any undefined entries
            .map(beer => ({
              value: beer.value,
              label: beer.label,
              count: beer.count || 0
            }));
          
          setDefaultOptions(defaultBeers);
        }
      } catch (error) {
        console.error('Error loading initial beer types:', error);
      }
    };

    loadInitialOptions();
  }, [database]);

  const loadOptions = useCallback(async (inputValue, callback) => {
    const formattedInput = inputValue.trim().toLowerCase();
    
    try {
      const beerTypesRef = ref(database, 'beerTypes');
      const snapshot = await get(beerTypesRef);
      let filteredOptions = [];
      
      if (snapshot.exists()) {
        const allBeers = Object.values(snapshot.val());
        filteredOptions = allBeers
          .filter(beer => beer.label.toLowerCase().includes(formattedInput))
          .map(beer => ({
            value: beer.value,
            label: beer.label,
            count: beer.count || 0
          }));
      }

      // Add "Add new" option if input doesn't match existing beers
      if (inputValue && !filteredOptions.some(beer => 
        beer.label.toLowerCase() === formattedInput.toLowerCase()
      )) {
        filteredOptions.unshift({
          value: inputValue,
          label: `Add "${inputValue}"`,
          __isNew__: true
        });
      }

      // Sort the filtered options by count
      const sortedOptions = sortBeersByCount(filteredOptions);
      
      // Return the options directly without grouping
      callback(sortedOptions);
    } catch (error) {
      console.error('Error loading beer types:', error);
      callback([]);
    }
  }, [database]);

  const handleChange = async (selectedOption) => {
    if (selectedOption?.__isNew__) {
      try {
        // Format the new beer name properly
        const formattedBeerName = selectedOption.value
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');

        // Create unique ID for the beer
        const id = formattedBeerName.toLowerCase().replace(/[^a-z0-9]/g, '-');
        
        // Create new beer type in Firebase
        const beerTypeRef = ref(database, `beerTypes/${id}`);
        const newBeerType = {
          value: formattedBeerName,
          label: formattedBeerName,
          count: 1,
          timestamp: new Date().toISOString()
        };

        await set(beerTypeRef, newBeerType);
        onChange(newBeerType);
      } catch (error) {
        console.error('Error adding new beer type:', error);
      }
    } else {
      // Update the count for the selected beer type
      try {
        const id = selectedOption.value.toLowerCase().replace(/[^a-z0-9]/g, '-');
        const beerTypeRef = ref(database, `beerTypes/${id}`);
        const snapshot = await get(beerTypeRef);
        
        if (snapshot.exists()) {
          const beer = snapshot.val();
          await set(beerTypeRef, {
            ...beer,
            count: (beer.count || 0) + 1
          });
        }
      } catch (error) {
        console.error('Error updating beer count:', error);
      }
      
      onChange(selectedOption);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDark ? '#1F2937' : '#f8fafc',
      borderColor: state.isFocused ? '#3b82f6' : (isDark ? '#334155' : '#d1d5db'),
      boxShadow: state.isFocused ? '0 0 0 1px #3b82f6' : 'none',
      '&:hover': {
        borderColor: '#3b82f6',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDark ? '#1F2937' : '#ffffff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3b82f6' : (isDark ? '#111827' : '#ffffff'),
      color: state.isSelected ? '#ffffff' : (isDark ? '#e5e7eb' : '#000000'),
      '&:hover': {
        backgroundColor: '#2563eb',
        color: '#ffffff',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDark ? '#e5e7eb' : '#000000',
    }),
    input: (provided) => ({
      ...provided,
      color: isDark ? '#e5e7eb' : '#000000',
    }),
  };

  return (
    <div>
      <AsyncSelect
        value={selectedBeerType}
        inputValue={inputValue}
        onInputChange={(newValue) => setInputValue(newValue)}
        loadOptions={loadOptions}
        onChange={handleChange}
        styles={customStyles}
        cacheOptions
        defaultOptions={defaultOptions}
        placeholder="Search, select or add a pint..."
      />
      <p className="text-xs mt-1 text-green-700 dark:text-green-500">
        Can't find your pint? You can now add your own!
      </p>
    </div>
  );
};

export default BeerTypeSelect; 