import React, { useState } from 'react';
import { addManualPub } from '../utils/ManualPubHandle';

const AddPubForm = ({ searchQuery, onClose, onSuccess }) => {
  const [pubData, setPubData] = useState({
    pubName: searchQuery,
    roadName: '',
    town: '',
    postcode: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = `manual_${pubData.pubName.toLowerCase().replace(/[^a-z0-9]/g, '_')}`;
    
    try {
      const formattedPub = {
        id,
        pub: pubData.pubName,
        roadName: pubData.roadName,
        town: pubData.town,
        postcode: pubData.postcode,
        source: 'manual',
        verified: false,
        pints: [],
        lat: null,
        lng: null
      };

      const success = await addManualPub(formattedPub);    
      if (success) {
        onSuccess({
          value: id,
          label: pubData.pubName,
          pubName: pubData.pubName,
          roadName: pubData.roadName,
          town: pubData.town,
          postcode: pubData.postcode,
          source: 'manual',
          isLabel: false,
          pub: pubData.pubName
        });
        onClose();
      }
    } catch (error) {
    //   console.error('AddPubForm: Error adding pub:', error);
    }
  };

  return (
    <div className="w-full lg:max-w-xl  bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
      <h3 className="text-lg font-semibold mb-4 dark:text-white">Add Missing Pub</h3>
      
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium mb-1 dark:text-gray-200">Pub Name *</label>
          <input
            required
            type="text"
            value={pubData.pubName}
            onChange={(e) => setPubData({ ...pubData, pubName: e.target.value })}
            className="w-full border rounded px-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 dark:text-gray-200">Road Name *</label>
          <input
            required
            type="text"
            value={pubData.roadName}
            onChange={(e) => setPubData({ ...pubData, roadName: e.target.value })}
            className="w-full border rounded px-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 dark:text-gray-200">Town *</label>
          <input
            required
            type="text"
            value={pubData.town}
            onChange={(e) => setPubData({ ...pubData, town: e.target.value })}
            className="w-full border rounded px-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
        </div>

        <div>
          <label className="block text-sm font-medium mb-1 dark:text-gray-200">Postcode *</label>
          <input
            required
            type="text"
            value={pubData.postcode}
            onChange={(e) => setPubData({ ...pubData, postcode: e.target.value.toUpperCase() })}
            className="w-full border rounded px-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            pattern="[A-Za-z]{1,2}[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}"
          />
        </div>
      </div>
      
      <button 
        onClick={handleSubmit}
        className="w-full mt-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 dark:bg-green-600 dark:hover:bg-green-700"
      >
        Add Pub
      </button>
    </div>
  );
};

export default AddPubForm;