import React, { useEffect, useState, useCallback } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { formatDistanceToNow } from "date-fns";
import Select from 'react-select';
import { Link } from 'react-router-dom';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "average-pint-london.firebaseapp.com",
  databaseURL: "https://average-pint-london-default-rtdb.europe-west1.firebasedatabase.app/",
  projectId: "average-pint-london",
  storageBucket: "average-pint-london.appspot.com",
  messagingSenderId: "284009350282",
  appId: "1:284009350282:web:ceec7375a8a98f60e81973",
  measurementId: "G-3DE63Z7MQE"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const cities = [
  { value: 'london', label: 'London' },
  { value: 'manchester', label: 'Manchester' },
  { value: 'birmingham', label: 'Birmingham' },
  { value: 'edinburgh', label: 'Edinburgh' },
  { value: 'liverpool', label: 'Liverpool' },
  { value: 'glasgow', label: 'Glasgow' }
];

const CheapestPintByCity = () => {
  const [selectedCity, setSelectedCity] = useState(cities[0]);
  const [cheapestPints, setCheapestPints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showWetherspoons, setShowWetherspoons] = useState(true);

  const updateCheapestPints = useCallback((pints, city) => {
    console.log(`Filtering pints for city: ${city.value}`);
    
    const filteredPints = pints
      .filter(pint => {
        const addressParts = pint.pub?.split(',').map(part => part.trim().toLowerCase()) || [];
        const hasCity = addressParts.some(part => part.includes(city.value));
        
        const postcodePart = addressParts[addressParts.length - 1] || '';
        const postcodeMatches = 
          (city.value === 'london' && postcodePart.match(/^[ENSWenswc]\d/)) ||
          (city.value === 'manchester' && postcodePart.match(/^M\d/)) ||
          (city.value === 'birmingham' && postcodePart.match(/^B\d/)) ||
          (city.value === 'liverpool' && postcodePart.match(/^L\d/)) ||
          (city.value === 'glasgow' && postcodePart.match(/^G\d/)) ||
          (city.value === 'edinburgh' && postcodePart.match(/^EH\d/));
  
        const cityMatches = hasCity || postcodeMatches;
  
        if (pints.indexOf(pint) < 3) {
          console.log(`Checking pint:`, {
            pub: pint.pub,
            addressParts,
            hasCity,
            postcodeMatches,
            cityMatches
          });
        }
  
        const isWetherspoons = pint.pub?.toLowerCase().includes('wetherspoon');
        return cityMatches && (showWetherspoons || !isWetherspoons);
      })
      .sort((a, b) => {
        if (a.price === b.price) {
          return new Date(b.timestamp) - new Date(a.timestamp);
        }
        return a.price - b.price;
      });
  
    console.log(`Found ${filteredPints.length} pints for ${city.value}`);
  
    const uniquePints = [];
    const seenPrices = new Set();
    for (const pint of filteredPints) {
      if (!seenPrices.has(pint.price) && uniquePints.length < 3) {
        uniquePints.push(pint);
        seenPrices.add(pint.price);
      }
    }
  
    console.log(`Final unique pints for ${city.value}:`, uniquePints);
    setCheapestPints(uniquePints);
  }, [showWetherspoons]);

  useEffect(() => {
    const fetchCheapestPints = () => {
      setLoading(true);
      const database = firebase.database();
      const pricesRef = database.ref("beerPrices");

      pricesRef.on("value", (snapshot) => {
        try {
          const data = snapshot.val();
          if (!data) {
            console.log("No data received from Firebase");
            setLoading(false);
            return;
          }

          const pints = Object.values(data);
          console.log(`Fetched ${pints.length} pints from Firebase`);
          
          updateCheapestPints(pints, selectedCity);
          setLoading(false);
        } catch (error) {
          console.error("Error processing Firebase data:", error);
          setLoading(false);
        }
      }, (error) => {
        console.error("Firebase fetch error:", error);
        setLoading(false);
      });

      return () => pricesRef.off();
    };

    fetchCheapestPints();
  }, [selectedCity, updateCheapestPints]);

  const renderPintCard = (pint) => {
    const locationDetails = `${pint.pub}${pint.town ? `, ${pint.town}` : ''}`;
    
    return (
      <div key={pint.pub + pint.price} className="bg-white rounded-lg border border-gray-200 p-4 mb-4 dark:bg-gray-700 dark:border-gray-600">
        <Link to={`/pub/${pint.pub.replace(/\s+/g, '-').toLowerCase()}`} className="font-bold text-green-600 underline dark:text-green-400">
          {locationDetails}
        </Link>
        <p className="text-gray-600 dark:text-gray-400">{pint.beerType}</p>
        <p className="font-bold text-2xl dark:text-white">
          £{pint.price.toFixed(2)}
          {pint.happyHour && (
            <span className="align-middle bg-yellow-300 text-yellow-800 text-sm font-medium px-2.5 py-0.5 rounded dark:bg-yellow-300 dark:text-yellow-900 mb-2 ml-2">
              Happy Hour
            </span>
          )}
        </p>
        <small className="text-gray-500 dark:text-gray-400">
          {formatDistanceToNow(new Date(pint.timestamp), { addSuffix: true })}
        </small>
      </div>
    );
  };

  return (
    <div className="w-full mb-8 p-4 bg-white rounded-lg border dark:bg-gray-800 dark:border-gray-700">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4">
        <h2 className="text-2xl font-bold mb-2 sm:mb-0 dark:text-white">Cheapest pints in</h2>
        <div className="w-full sm:w-48">
          <Select
            options={cities}
            value={selectedCity}
            onChange={(newCity) => {
              setSelectedCity(newCity);
              setLoading(true);
            }}
            className="text-sm"
            isDisabled={loading}
          />
        </div>
      </div>
      
      <label className="flex items-center cursor-pointer mb-4">
        <span className="mr-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {showWetherspoons ? "Include" : "Exclude"} Wetherspoons
        </span>
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only toggle-checkbox"
            checked={showWetherspoons}
            onChange={() => {
              setShowWetherspoons(!showWetherspoons);
              setLoading(true);
            }}
            disabled={loading}
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full border border-gray-200 toggle-bg dark:bg-gray-600 dark:border-gray-500"></div>
          <div className="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition dark:bg-gray-400"></div>
        </div>
      </label>

      {loading ? (
        <div className="flex items-center justify-center py-4">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-green-500"></div>
          <span className="ml-2">Loading pints...</span>
        </div>
      ) : cheapestPints.length > 0 ? (
        <div>
          {cheapestPints.map(renderPintCard)}
        </div>
      ) : (
        <p>No pints found in {selectedCity.label}</p>
      )}
    </div>
  );
};

export default CheapestPintByCity;