const RECENT_PUBS_KEY = 'recentPubs';
const MAX_RECENT_PUBS = 3;

export const addToRecentPubs = (pub) => {
  try {
    // Get user's reported pints
    const reportedPints = JSON.parse(localStorage.getItem('reportedPintDetails') || '[]');
    
    // Only add pub if user has submitted a pint there
    const hasPintAtPub = reportedPints.some(pint => 
      pint.pub === (pub.pub || pub.pubName) && 
      pint.roadName === pub.roadName
    );

    if (!hasPintAtPub) {
      return;
    }

    const storedPubs = localStorage.getItem(RECENT_PUBS_KEY);
    let recentPubs = storedPubs ? JSON.parse(storedPubs) : [];
    
    // Remove any existing entry for this pub
    recentPubs = recentPubs.filter(recent => 
      !(recent.pubName === (pub.pub || pub.pubName) && recent.roadName === pub.roadName)
    );
    
    // Add the new pub at the start with all required fields for PubsSelect
    recentPubs.unshift({
      pubName: pub.pub || pub.pubName,
      roadName: pub.roadName,
      town: pub.town,
      lat: pub.lat,
      lng: pub.lng,
      formatted_address: `${pub.pub || pub.pubName}, ${pub.roadName}, ${pub.town}`,
      source: 'recent',
      id: `${pub.pub || pub.pubName}-${pub.roadName}`.toLowerCase().replace(/\s+/g, '-'),
      name: pub.pub || pub.pubName
    });
    
    // Keep only the most recent pubs
    recentPubs = recentPubs.slice(0, MAX_RECENT_PUBS);
    
    localStorage.setItem(RECENT_PUBS_KEY, JSON.stringify(recentPubs));
  } catch (error) {
    console.error('Error saving recent pub:', error);
  }
};

export const getRecentPubs = async () => {
    try {
      // Get user's reported pints to verify recent pubs
      const reportedPints = JSON.parse(localStorage.getItem('reportedPintDetails') || '[]');
      const userPubKeys = new Set(
        reportedPints.map(pint => `${pint.pub}_${pint.roadName}`.toLowerCase())
      );
  
      const storedPubs = localStorage.getItem(RECENT_PUBS_KEY);
      const recentPubs = storedPubs ? JSON.parse(storedPubs) : [];
      
      // Only return pubs where the user has actually submitted pints
      return recentPubs.filter(pub => 
        userPubKeys.has(`${pub.pubName}_${pub.roadName}`.toLowerCase())
      );
    } catch (error) {
      console.error('Error fetching recent pubs:', error);
      return []; // Always return an array
    }
  };