import { initializeApp } from 'firebase/app';
import { getDatabase, ref, query, orderByChild, startAt, endAt, get } from 'firebase/database';
import { database } from './ManualPubHandle';
import { firebaseConfig } from './firebaseConfig';
import { getRecentPubs } from './recentPubsService';

// Initialize Firebase
const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
const MAPBOX_API_ENDPOINT = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
const NOMINATIM_API_ENDPOINT = "https://nominatim.openstreetmap.org";

// Add debounce utility
const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(func(...args)), wait);
    });
  };
};

const formatPubData = (pub) => ({
  value: pub.id,
  label: pub.name,
  pubName: pub.name,
  roadName: pub.road || '',
  town: pub.town || '',
  postcode: pub.postcode || '',
  isLabel: false,
  source: pub.source
});

const searchMapbox = async (query, proximity) => {
  if (!query) return [];
  
  try {
    const params = new URLSearchParams({
      access_token: process.env.REACT_APP_MAPBOX_API_KEY,
      types: 'poi',
      limit: '10',
      proximity: proximity ? `${proximity.longitude},${proximity.latitude}` : undefined,
      category: 'bar,pub,restaurant,beer',
      country: 'gb',
      bbox: '-8.74,49.84,1.96,60.86',
      not_category: 'shop,store,supermarket,grocery'
    });

    const response = await fetch(
      `${MAPBOX_API_ENDPOINT}/${encodeURIComponent(query)}.json?${params}`
    );
    
    if (!response.ok) throw new Error('Mapbox API error');
    
    const data = await response.json();
    return data.features.map(feature => formatPubData({
      id: `mapbox_${feature.id}`,
      name: feature.text,
      road: feature.properties?.address || '',
      town: feature.context?.find(c => c.id.startsWith('place'))?.text || '',
      postcode: feature.context?.find(c => c.id.startsWith('postcode'))?.text || '',
      source: 'mapbox',
      lat: feature.center[1],
      lng: feature.center[0]
    }));
  } catch (error) {
    return [];
  }
};

const searchNominatim = async (query) => {
  if (!query) return [];
  
  try {
    const params = new URLSearchParams({
      q: query,
      format: 'json',
      countrycodes: 'gb',
      limit: '10',
      addressdetails: '1',
      category: 'amenity:pub|amenity:bar|amenity:restaurant',
      extratags: '1',
      viewbox: '-8.74,60.86,1.96,49.84',
      bounded: '1'
    });

    const response = await fetch(
      `${NOMINATIM_API_ENDPOINT}/search?${params}`,
      {
        headers: {
          'User-Agent': 'PintPriceApp/1.0',
          'Accept-Language': 'en-GB'
        }
      }
    );
    
    if (!response.ok) throw new Error('Nominatim API error');
    
    const data = await response.json();
    
    return data
      .filter(place => {
        const amenity = place.extratags?.amenity || place.type;
        return ['pub', 'bar', 'restaurant'].includes(amenity?.toLowerCase());
      })
      .map(pub => formatPubData({
        id: `nominatim_${pub.place_id}`,
        name: pub.display_name.split(',')[0],
        road: pub.address?.road || '',
        town: pub.address?.city || pub.address?.town || '',
        postcode: pub.address?.postcode || '',
        source: 'nominatim',
        lat: parseFloat(pub.lat),
        lng: parseFloat(pub.lon)
      }));
  } catch (error) {
    return [];
  }
};
const deduplicateResults = (results) => {
  const seen = new Map();
  
  return results.filter(pub => {
    const key = `${pub.pubName.toLowerCase()}_${pub.roadName.toLowerCase()}`;
    
    if (seen.has(key)) {
      const existing = seen.get(key);
      if (pub.source === 'manual' && pub.verified) {
        seen.set(key, pub);
        return true;
      }
      if (pub.source === 'mapbox' && existing.source === 'nominatim') {
        seen.set(key, pub);
        return true;
      }
      return false;
    }
    
    seen.set(key, pub);
    return true;
  });
};

const searchManualPubs = async (searchQuery) => {
  if (!searchQuery) return [];

  try {
    const pubsRef = ref(database, 'manualPubs');
    const pubQuery = query(
      pubsRef,
      orderByChild('pub'),
      startAt(searchQuery.toLowerCase()),
      endAt(searchQuery.toLowerCase() + '\uf8ff')
    );
    
    const snapshot = await get(pubQuery);
    const pubs = [];
    
    snapshot.forEach((child) => {
      const pub = child.val();
      pubs.push(formatPubData({
        id: child.key,
        name: pub.pub,
        road: pub.roadName,
        town: pub.town,
        postcode: pub.postcode,
        source: 'manual',
        verified: pub.verified || false
      }));
    });
    
    return pubs;
  } catch (error) {
    // console.error('Error searching manual pubs:', error);
    return [];
  }
};

const debouncedSearch = debounce(async (query, proximity) => {
  if (!query) return [];

  try {
    // Run all searches in parallel
    const [mapboxResults, nominatimResults, manualResults] = await Promise.allSettled([
      searchMapbox(query, proximity),
      searchNominatim(query),
      searchManualPubs(query)
    ]);

    // Combine successful results
    const allResults = []
      .concat(
        mapboxResults.status === 'fulfilled' ? mapboxResults.value : [],
        nominatimResults.status === 'fulfilled' ? nominatimResults.value : [],
        manualResults.status === 'fulfilled' ? manualResults.value : []
      )
      .filter(Boolean);

    // Deduplicate results
    return deduplicateResults(allResults);
  } catch (error) {
    // console.error("Error in debouncedSearch:", error);
    return [];
  }
}, 300);

const combineWithRecentPubs = async (results, isNearbySearch = false) => {
  try {
    const recentPubs = await getRecentPubs();
    let combinedResults = [];
    
    // Add recent pubs if available
    if (recentPubs && recentPubs.length > 0) {
      combinedResults = [
        { isLabel: true, label: 'Recent Pubs' },
        ...recentPubs
      ];
    }
    
    // Filter out recent pubs from results
    const filteredResults = results.filter(pub => 
      !recentPubs.some(recent => 
        recent.pubName.toLowerCase() === pub.pubName.toLowerCase() &&
        recent.roadName?.toLowerCase() === pub.roadName?.toLowerCase()
      )
    );
    
    // Add nearby or search results if available
    if (filteredResults.length > 0) {
      combinedResults.push(
        { isLabel: true, label: isNearbySearch ? 'Nearby Pubs & Bars' : 'Search Results' },
        ...filteredResults
      );
    }
    
    return combinedResults;
  } catch (error) {
    return results;
  }
};

export const searchPubs = async (query, proximity = null, isNearbySearch = false) => {
  try {
    const results = await debouncedSearch(query, proximity);
    return await combineWithRecentPubs(results, isNearbySearch);
  } catch (error) {
    return [];
  }
};
