import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePintContext } from '../utils/PintContext';
import { getGuinnessRatingLabel } from '../utils/guinnessRating';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { sendAnonymousAnalytics } from '../utils/analytics';
import { addToRecentPubs } from '../utils/recentPubsService';

const createGoogleMapsLink = (pubName) => {
  return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(pubName)}`;
};

const getLevelInfo = (pintCount) => {
  const levels = [
    { 
      threshold: 0, 
      name: "Level 1 - What's beer? 🤷‍♂️",
      description: "Welcome to the world of pint tracking! Everyone starts somewhere."
    },
    { 
      threshold: 3, 
      name: "Level 2 - Beginner brew 🟩",
      description: "You're getting the hang of this! Starting to explore different pubs and prices."
    },
    { 
      threshold: 5, 
      name: "Level 3 - Pint enthusiast 🍺",
      description: "Now you're developing a taste for tracking! Your contributions are helping others find good deals."
    },
    { 
      threshold: 10, 
      name: "Level 4 - Pub regular 🍻",
      description: "A seasoned contributor! Your knowledge of local pint prices is growing strong."
    },
    { 
      threshold: 20, 
      name: "Level 5 - Local legend 🐉",
      description: "You're becoming famous in the pint-tracking community! Your dedication to finding great deals is impressive."
    },
    { 
      threshold: 30, 
      name: "Level 6 - Pints are life 🛟 ✨",
      description: "Thank you for your service you true pint tracking hero!"
    }
  ];
  
  let currentLevel = levels[0];
  let nextLevel = levels[1];
  
  for (let i = 0; i < levels.length - 1; i++) {
    if (pintCount >= levels[i].threshold && pintCount < levels[i + 1].threshold) {
      currentLevel = levels[i];
      nextLevel = levels[i + 1];
      break;
    }
  }
  
  // Handle the case when the user is at the highest level
  if (pintCount >= levels[levels.length - 1].threshold) {
    currentLevel = levels[levels.length - 1];
    nextLevel = currentLevel;
  }
  
  let progress;
  if (currentLevel === nextLevel) {
    progress = 100;
  } else {
    const rangeSize = nextLevel.threshold - currentLevel.threshold;
    const progressInRange = pintCount - currentLevel.threshold;
    progress = (progressInRange / rangeSize) * 100;
  }
  
  return { currentLevel, nextLevel, progress };
};

const MyPints = () => {
  const [reportedPints, setReportedPints] = useState([]);
  const [isConfirming, setIsConfirming] = useState(false);
  const [levelInfo, setLevelInfo] = useState({ currentLevel: { name: "What's beer?", threshold: 0 }, nextLevel: { name: "Beginner brew", threshold: 1 }, progress: 0 });
  const { updateReportedPints } = usePintContext();
  const navigate = useNavigate();
  const [showBanner, setShowBanner] = useState(true);

  const handleSubmitPintClick = (e) => {
    e.preventDefault();
    navigate('/#beerForm', { state: { scrollToForm: true } });
  };

  useEffect(() => {
    loadPints();
  }, []);

  const loadPints = () => {
    const storedPints = JSON.parse(localStorage.getItem('reportedPintDetails') || '[]');
    const sortedPints = storedPints.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    
    // Add each unique pub to recent pubs
    const uniquePubs = new Map();
    sortedPints.forEach(pint => {
      const key = `${pint.pub}_${pint.roadName}`;
      if (!uniquePubs.has(key)) {
        uniquePubs.set(key, pint);
        addToRecentPubs({
          pub: pint.pub,
          pubName: pint.pub,
          roadName: pint.roadName,
          town: pint.town,
          lat: pint.lat,
          lng: pint.lng
        });
      }
    });
    window.dispatchEvent(new Event('storage'));
    setReportedPints(sortedPints);
    updateReportedPints(sortedPints.length);
    const newLevelInfo = getLevelInfo(sortedPints.length);
    setLevelInfo(newLevelInfo);
  
    // Send anonymous analytics
    sendAnonymousAnalytics({
      pintCount: sortedPints.length,
      level: newLevelInfo.currentLevel.name,
      progress: newLevelInfo.progress
    });
  };

  const handleClearAllPints = () => {
    if (isConfirming) {
      localStorage.removeItem('reportedPintDetails');
      setReportedPints([]);
      updateReportedPints(0);
      setLevelInfo(getLevelInfo(0));
      setIsConfirming(false);
    } else {
      setIsConfirming(true);
    }
  };

  return (
    <div className="container mx-auto max-w-7xl p-4 dark:bg-gray-900">
      <Link to="/" className="mb-4 sm:mb-8 inline-block underline text-green-600 hover:text-green-900 dark:text-green-400 dark:hover:text-green-300">
        ← Back
      </Link>
      <h1 className="text-2xl sm:text-4xl font-bold mb-4 dark:text-white">My Reported Pints</h1>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start">
        <div className="flex-1 mb-6 border border-gray-200 rounded-lg p-4 dark:border-gray-700">
          <h2 className="text-xl font-semibold mb-2 dark:text-white">Level: {levelInfo.currentLevel.name}</h2>
          <p className="text-sm mb-4 dark:text-gray-300">{levelInfo.currentLevel.description}</p>
          <div className="w-full sm:w-64 bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div 
              className="bg-green-600 h-2.5 rounded-full" 
              style={{ width: `${Math.min(100, Math.max(0, levelInfo.progress))}%` }}
            ></div>
          </div>
          <p className="text-sm mt-1 dark:text-gray-300">
            {reportedPints.length} / {levelInfo.nextLevel.threshold} pints to "{levelInfo.nextLevel.name}"
          </p>
        </div>
      </div>

      <div className="border border-gray-200 p-4 rounded-lg dark:border-gray-700">
        {showBanner && (
          <div className="flex items-center justify-between p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-blue-900 dark:text-blue-200" role="alert">
            <div className="flex items-center">
              <svg className="flex-shrink-0 inline w-4 h-4 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 1 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
              <div>
                <span className="font-medium">No account required:</span> Pint contributions are saved locally in your browser which is why you don't need an account. If you clear your browser history, use in incognito or another device, these will be lost.
              </div>
            </div>
            <button 
              onClick={() => setShowBanner(false)}
              className="ml-4 text-blue-800 dark:text-blue-200 hover:text-blue-600 dark:hover:text-blue-300"
              aria-label="Close banner"
            >
              <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        )}
        {reportedPints.length === 0 ? (
          <>
            <p className="dark:text-white">You haven't reported any pints yet.</p>
            <Link to="/#beerForm" onClick={handleSubmitPintClick} className="mt-4 inline-block underline text-green-600 hover:text-green-900 dark:text-green-400 dark:hover:text-green-300">
              Submit a pint →
            </Link>
          </>
        ) : (
          <ul className="space-y-4">
            {reportedPints.map((pint, index) => {
              const isGuinness = pint.beerType.toLowerCase() === 'guinness';
              const hasGuinnessRating = pint.guinnessRating !== undefined && pint.guinnessRating !== null;
              return (
                <li key={index} className="bg-white shadow rounded-lg p-4 dark:bg-gray-800">
                  <p className="font-bold dark:text-white">{pint.beerType}</p>
                  <p className="text-2xl font-bold dark:text-white">
                    £{pint.price.toFixed(2)}
                    {isGuinness && hasGuinnessRating && (
                      <span className="align-middle bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full ml-2 dark:bg-gray-600 dark:text-gray-200">
                        {getGuinnessRatingLabel(pint.guinnessRating)}
                      </span>
                    )}
                  </p>
                  <a 
                    href={createGoogleMapsLink(pint.pub)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-600 hover:text-green-800 dark:text-green-400 dark:hover:text-green-300"
                  >
                    {pint.pub}
                  </a>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    Reported {formatDistanceToNow(parseISO(pint.timestamp))} ago
                  </p>
                </li>
              );
            })}
          </ul>
        )}
              <div className="flex justify-center">
          {reportedPints.length > 0 && (
            <button
              onClick={handleClearAllPints}
              className={`border ${isConfirming ? 'border-red-700 bg-red-50' : 'border-red-700'} mt-8 hover:bg-red-700 text-red-500 font-bold py-2 px-4 text-sm rounded mb-4 dark:bg-red-900 dark:text-red-300`}
            >
              {isConfirming ? "Confirm delete all pints?" : "Delete all Pints"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyPints;