import React, { useState, useMemo, useEffect } from "react";
import Select from "react-select";
import beerTypes from "../utils/beerTypes";
import PubsSelect from "./PubsSelect";
import { getDatabase, ref, push } from "firebase/database";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Confetti from 'react-confetti';
import { useTheme } from "../utils/ThemeContext";
import BeerTypeSelect from "./BeerTypeSelect";

const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY; 
const MAPBOX_API_ENDPOINT = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

const LevelUpModal = ({ isOpen, onClose, levelName }) => {
  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      role="dialog"
      aria-modal="true"
      aria-labelledby="levelUpTitle"
    >
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h2 id="levelUpTitle" className="text-2xl font-bold mb-4">🎉 Level Up!</h2>
        <p className="text-xl mb-6">You've reached "{levelName}"!</p>
        <Link 
          to="/my-pints" 
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
          onClick={() => {
            onClose();
            setTimeout(() => window.scrollTo(0, 0), 100);
          }}
        >
          View My Pints
        </Link>
        <button 
          onClick={onClose}
          className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const getLevelInfo = (pintCount) => {
  const levels = [
    { threshold: 0, name: "1 - What's beer? 🤷‍♂️" },
    { threshold: 3, name: "2 - Beginner brew 🟩" },
    { threshold: 5, name: "3 - Pint enthusiast 🍺" },
    { threshold: 10, name: "4 - Pub regular 🍻" },
    { threshold: 20, name: "5 - Local legend 🐉" },
    { threshold: 30, name: "6 - Pints are life 🛟 ✨" }
  ];
  
  let currentLevel = levels[0];
  let nextLevel = levels[1];
  
  for (let i = 0; i < levels.length - 1; i++) {
    if (pintCount >= levels[i].threshold && pintCount < levels[i + 1].threshold) {
      currentLevel = levels[i];
      nextLevel = levels[i + 1];
      break;
    }
  }
  
  if (pintCount >= levels[levels.length - 1].threshold) {
    currentLevel = levels[levels.length - 1];
    nextLevel = currentLevel;
  }
  
  let progress;
  if (currentLevel === nextLevel) {
    progress = 100;
  } else {
    const rangeSize = nextLevel.threshold - currentLevel.threshold;
    const progressInRange = pintCount - currentLevel.threshold;
    progress = (progressInRange / rangeSize) * 100;
  }
  
  return { currentLevel, nextLevel, progress };
};

const BeerPriceForm = () => {
  const [selectedPub, setSelectedPub] = useState(null);
  const [price, setPrice] = useState("");
  const [selectedBeerType, setSelectedBeerType] = useState(null);
  const [beerGarden, setBeerGarden] = useState(false);
  const [liveSports, setLiveSports] = useState(false);
  const [liveMusic, setLiveMusic] = useState(false);
  const [error, setError] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [pubError, setPubError] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [beerTypeError, setBeerTypeError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const [showPriceError, setShowPriceError] = useState(false);
  const [happyHour, setHappyHour] = useState(false);
  const [happyHourStart, setHappyHourStart] = useState('');
  const [happyHourEnd, setHappyHourEnd] = useState('');
  const [happyHourStartPeriod, setHappyHourStartPeriod] = useState('PM');
  const [happyHourEndPeriod, setHappyHourEndPeriod] = useState('PM');
  const [happyHourDays, setHappyHourDays] = useState([]);
  const [guinnessRating, setGuinnessRating] = useState(5);
  const [showGuinnessRating, setShowGuinnessRating] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [showLevelUpModal, setShowLevelUpModal] = useState(false);
  const [newLevelName, setNewLevelName] = useState("");
  const { isDark } = useTheme();
  const [pintSubmitted, setPintSubmitted] = useState(false);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const daysOfWeek = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
  ];

  const guinnessRatingDescriptions = useMemo(() => [
    "💩 Absolute Shit: Tastes like someone scooped toilet water and tried to pass it off as Guinness. Should never have seen the light of day, let alone your glass.",
    "🤮 Barely Drinkable: Slightly better than actual dirt, but still, you'd rather be sober.",
    "🤢 Really Bad: Watery with a bitter aftertaste, missing all the smoothness. Feels like a punishment.",
    "😩 Below Average: Lacks the signature creaminess, and tastes more like a beer that tried to be Guinness but failed miserably.",
    "🥱 Meh: Middle of the road. It's drinkable, but you're not impressed. Maybe the pour was bad.",
    "🙂 Passable: Has some decent head and flavor, but missing that extra smoothness. You'd drink it, but you're not rushing for another.",
    "😌 Decent Pint: Smooth, creamy, and flavorful. A pint you'd happily drink but not quite the best.",
    "🤤 Solid Creamer: Well-poured, rich, and full-bodied. Hits the spot with just enough creaminess.",
    "🤩 Near Perfect: Creamy, smooth, and with a beautiful velvety texture. Hits all the right notes, close to Guinness nirvana.",
    "🤤 Absolute Creamer: This is it. Perfect pour, luscious cream on top, rich body, smooth as silk. You're in heaven. Every Guinness lover's dream."
  ], []);

  const formatAddress = (pub) => {
    const { pubName, roadName, town, postcode } = pub;
    const addressParts = [
      pubName.trim(),
      roadName.trim(),
      town ? town.trim() : '',
      postcode ? postcode.trim() : '',
      'UK'
    ].filter(Boolean);
    return addressParts.join(', ');
  };

  const handleRecaptchaChange = (response) => {
    setRecaptchaError(false);
    setRecaptchaResponse(response);
  };

  const handlePubChange = (selectedOption) => {
    setSelectedPub(selectedOption);
    setPubError(false);
  };

  const handlePriceChange = (event) => {
    const enteredPrice = parseFloat(event.target.value);
    if (isNaN(enteredPrice)) {
      setPrice("");
      setPriceError(false);
    } else if (enteredPrice >= 1 && enteredPrice <= 20) {
      setPrice(enteredPrice);
      setPriceError(false);
    } else {
      setPrice("");
      setPriceError(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!recaptchaResponse) {
      setRecaptchaError(true);
      return;
    }

    setError(false);

    if (!selectedPub) {
      setPubError(true);
      return;
    }

    if (!price) {
      setPriceError(true);
      return;
    }

    if (!selectedBeerType) {
      setBeerTypeError(true);
      return;
    }

    try {
      const fullAddress = formatAddress(selectedPub);
      const address = encodeURIComponent(fullAddress);
      const mapboxResponse = await axios.get(
        `${MAPBOX_API_ENDPOINT}${address}.json`,
        {
          params: {
            access_token: MAPBOX_API_KEY,
          },
        }
      );

      if (mapboxResponse.status === 200 && mapboxResponse.data.features.length > 0) {
        const coordinates = mapboxResponse.data.features[0].center;

        const database = getDatabase();
        const pricesRef = ref(database, "beerPrices");
        const newPriceData = {
          beerType: selectedBeerType.value,
          price: parseFloat(price),
          pub: fullAddress,
          lat: coordinates[1],
          lng: coordinates[0],
          beerGarden,
          liveSports,
          liveMusic,
          happyHour,
          happyHourDays: happyHour ? happyHourDays : [],
          happyHourStart: happyHour ? happyHourStart : null,
          happyHourEnd: happyHour ? happyHourEnd : null,
          guinnessRating: selectedBeerType.value.toLowerCase() === 'guinness' ? guinnessRating : null,
          timestamp: new Date().toISOString(),
        };

        await push(pricesRef, newPriceData);

        const currentPints = JSON.parse(localStorage.getItem('reportedPintDetails') || '[]').length;
        const previousLevelInfo = getLevelInfo(currentPints);
        const newLevelInfo = getLevelInfo(currentPints + 1);
        if (newLevelInfo.currentLevel.threshold > previousLevelInfo.currentLevel.threshold) {
          console.log("Level up detected! Showing confetti and modal...");
          setShowConfetti(true);
          setNewLevelName(newLevelInfo.currentLevel.name);
          setShowLevelUpModal(true);
          setTimeout(() => setShowConfetti(false), 5000);
    
          toast.success(
            <div>
              🎉 Level Up! You've reached "{newLevelInfo.currentLevel.name}"!<br/>
              <Link 
                className="underline" 
                to="/my-pints" 
                onClick={() => setTimeout(() => window.scrollTo(0, 0), 100)}
              >
                View My Pints
              </Link>
            </div>,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        } else {
          toast.success(
            <div>
              Pint added! 🍺 <Link 
                className="underline" 
                to="/my-pints" 
                onClick={() => setTimeout(() => window.scrollTo(0, 0), 100)}
              >
                View My Pints
              </Link>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
        
        setSelectedPub(null);
        setPrice("");
        setSelectedBeerType(null);
        setBeerGarden(false);
        setLiveSports(false);
        setLiveMusic(false);
        setError(false);
        setSubmitted(true);
        setShowMessage(true);

        setTimeout(() => {
          setShowMessage(false);
        }, 5000);

        const newPintCount = currentPints + 1;
        localStorage.setItem('reportedPints', newPintCount.toString());

        const pintDetails = {
          beerType: selectedBeerType.value,
          price: parseFloat(price),
          pub: selectedPub.label,
          timestamp: new Date().toISOString(),
          guinnessRating: selectedBeerType.value.toLowerCase() === 'guinness' ? guinnessRating : null
        };
        
        const storedPints = JSON.parse(localStorage.getItem('reportedPintDetails') || '[]');
        storedPints.push(pintDetails);
        localStorage.setItem('reportedPintDetails', JSON.stringify(storedPints));

        setPintSubmitted(prev => !prev);
      }
    } catch (error) {
      setError(true);
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: isDark ? '#1F2937' : '#f8fafc',
      borderColor: state.isFocused ? '#3b82f6' : (isDark ? '#334155' : '#d1d5db'),
      boxShadow: state.isFocused ? `0 0 0 1px #3b82f6` : 'none',
      '&:hover': {
        borderColor: '#3b82f6',
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: isDark ? '#1F2937' : '#ffffff',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#3b82f6' : (isDark ? '#111827' : '#ffffff'),
      color: state.isSelected ? '#ffffff' : (isDark ? '#e5e7eb' : '#000000'),
      '&:hover': {
        backgroundColor: '#2563eb',
        color: '#ffffff',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: isDark ? '#e5e7eb' : '#000000',
    }),
    input: (provided) => ({
      ...provided,
      color: isDark ? '#e5e7eb' : '#000000',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: isDark ? '#9CA3AF' : '#6B7280',
    }),
  };

  return (
    <div className="min-h-screen bg-white text-black dark:bg-slate-900 dark:text-white">
      {showConfetti && (
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          recycle={false}
          numberOfPieces={200}
          friction={0.95} 
          gravity={0.1}
          style={{
            position: 'fixed',
            pointerEvents: 'none',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 9999
          }}
        />
      )}
      <form 
        onSubmit={handleSubmit} 
        className="bg-white dark:bg-slate-800 border border-gray-300 dark:border-gray-700 rounded-lg p-4 sm:p-8 mb-8"
      >
        <h2 className="text-xl mb-2 font-bold">Submit a pint!</h2>
        <p className="text-s sm:text-md mb-4 sm:mb-8 font-medium text-slate-700 dark:text-slate-300">
          This website relies on fellow pint drinkers (or anyone!) to contribute pint prices. Please be as accurate as possible, the more contributions we get, the more helpful we are!
        </p>
        <div className="mb-8">
          <label htmlFor="pub" className="block font-medium mb-1">
            Search for a Pub:
          </label>
          <PubsSelect 
            selectedPub={selectedPub} 
            onChange={handlePubChange}
            onPintSubmitted={pintSubmitted}
          />
          {pubError && (
            <p id="pub-error" className="text-red-500 dark:text-red-400 text-sm mt-1">Please select a pub.</p>
          )}
        </div>
        <div className="mb-8">
          <label htmlFor="price" className="block font-medium mb-1">
            Enter the price of your pint:
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 rounded-l-md flex items-center text-gray-700 dark:text-gray-300">
              £
            </span>
            <input
              type="number"
              inputMode="decimal"
              pattern="[0-9]*\.?[0-9]*"
              id="price"
              name="price"
              value={price}
              onChange={handlePriceChange}
              className={`w-full pl-8 pr-3 py-2 border rounded-md dark:bg-gray-800 dark:text-gray-100 ${
                priceError ? "border-red-500 dark:border-red-400" : "border-gray-300 dark:border-gray-700"
              }`}
              aria-invalid={priceError}
              aria-describedby={priceError ? "price-error" : undefined}
            />
            {showPriceError && (
              <p id="price-error" className="text-red-500 dark:text-red-400 text-sm">
                Have you actually found a pint that's this price?{" "}
                <a
                  className="underline"
                  href="https://docs.google.com/forms/u/1/d/e/1FAIpQLSdyHWtxGXpyDhx6d8hSgHdk_eDNzHLLUJhJuF3-PDb7_SVy5Q/viewform?usp=sf_link"
                  target="_blank"
                  rel="noreferrer"
                >
                  Give feedback
                </a>
              </p>
            )}
          </div>
          {priceError && (
            <p id="price-error" className="text-red-500 dark:text-red-400 text-sm mt-1">Please enter a valid price between £1.50 and £14. If you've really found one that's cheaper or more expensive than this, then send me feedback below!</p>
          )}
        </div>
        <div className="mb-8">
          <label htmlFor="beerType" className="block font-medium mb-1">
            Choose a pint:
          </label>
          <BeerTypeSelect 
            selectedBeerType={selectedBeerType}
            onChange={(selectedOption) => {
              setSelectedBeerType(selectedOption);
              setBeerTypeError(false);
              setShowGuinnessRating(selectedOption?.value.toLowerCase() === 'guinness');
            }}
            onPintSubmitted={pintSubmitted}
          />
          {beerTypeError && (
            <p id="beerType-error" className="text-red-500 dark:text-red-400 text-sm mt-1">Please select a pint.</p>
          )}
        </div>
        {showGuinnessRating && (
          <div className="mb-8">
            <label htmlFor="guinnessRating" className="block font-medium mb-1">
              Rate your Guinness:
            </label>
            <input
              type="range"
              id="guinnessRating"
              name="guinnessRating"
              min="1"
              max="10"
              value={guinnessRating}
              onChange={(e) => setGuinnessRating(parseInt(e.target.value))}
              className="w-full mb-2 accent-green-600 dark:accent-green-400"
            />
            <div className="flex justify-between text-md mb-2">
              <span>1</span>
              <span>10</span>
            </div>
            <div className="bg-gray-100 dark:bg-slate-700 p-3 rounded-md">
              <p className="text-sm font-medium mb-1">
                {guinnessRating} - {guinnessRatingDescriptions[guinnessRating - 1].split(":")[0]}
              </p>
              <p className="text-sm">
                {guinnessRatingDescriptions[guinnessRating - 1].split(":")[1].trim()}
              </p>
            </div>
          </div>
        )}
        <fieldset className="mb-8">
          <legend className="block font-medium mb-1">Is there...</legend>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              id="beerGarden"
              checked={beerGarden}
              onChange={(e) => setBeerGarden(e.target.checked)}
              className="mr-2 accent-green-600 dark:accent-green-400 w-5 h-5"
            />
            <label htmlFor="beerGarden">🪴 Beer Garden?</label>
          </div>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              id="liveSports"
              checked={liveSports}
              onChange={(e) => setLiveSports(e.target.checked)}
              className="mr-2 accent-green-600 dark:accent-green-400 w-5 h-5"
            />
            <label htmlFor="liveSports">⚽️ Live Sports?</label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="liveMusic"
              checked={liveMusic}
              onChange={(e) => setLiveMusic(e.target.checked)}
              className="mr-2 accent-green-600 dark:accent-green-400 w-5 h-5"
            />
            <label htmlFor="liveMusic">🎶 Live Music?</label>
          </div>
        </fieldset>
        <div className="flex items-center mb-8">
          <input
            type="checkbox"
            id="happyHour"
            checked={happyHour}
            onChange={(e) => setHappyHour(e.target.checked)}
            className="mr-2 accent-green-600 dark:accent-green-400 w-5 h-5"
          />
          <label htmlFor="happyHour">🍻 Was this pint on Happy Hour?</label>
        </div>
        {happyHour && (
          <fieldset className="mb-8">
            <legend className="block font-medium mb-1">Happy Hour Details</legend>
            <label htmlFor="happyHourDays" className="block font-medium mb-1">What day(s) is happy hour? (select multiple)</label>
            <select
              multiple
              id="happyHourDays"
              value={happyHourDays}
              onChange={(e) => {
                const options = Array.from(e.target.selectedOptions, option => option.value);
                setHappyHourDays(options);
              }}
              className="border rounded-md w-full p-2 dark:bg-slate-800 dark:text-white dark:border-gray-700"
            >
              {daysOfWeek.map(day => (
                <option key={day.value} value={day.value}>
                  {day.label}
                </option>
              ))}
            </select>
            <label htmlFor="happyHourStart" className="block font-medium mb-1 mt-4">Happy hour start (24hr):</label>
            <input
              type="time"
              id="happyHourStart"
              value={happyHourStart}
              onChange={(e) => setHappyHourStart(e.target.value)}
              className="border rounded-md w-full p-2 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700"
            />
            <label htmlFor="happyHourEnd" className="block font-medium mb-1 mt-4">Happy hour end (24hr)</label>
            <input
              type="time"
              id="happyHourEnd"
              value={happyHourEnd}
              onChange={(e) => setHappyHourEnd(e.target.value)}
              className="border rounded-md w-full p-2 dark:bg-gray-800 dark:text-gray-100 dark:border-gray-700"
            />
          </fieldset>
        )}
        <ReCAPTCHA
          className="mb-4"
          sitekey="6LdXJPcnAAAAAERU_x3xpPuc3PLSE9tNxYjbSBei"
          onChange={handleRecaptchaChange}
        />
        {recaptchaError && (
          <p className="text-red-500 dark:text-red-400 text-sm mt-2 mb-4">
            Please complete the CAPTCHA to submit the form.
          </p>
        )}
        <button
          type="submit"
          className="bg-green-600 dark:bg-green-700 w-full text-white py-4 font-semibold py-2 px-4 mb-4 rounded-md"
        >
          Submit pint
        </button>
        {showMessage && (
          <p className="text-green-500 dark:text-green-400 text-md mt-2" role="status">Pint contributed!✅</p>
        )}
      </form>
      <LevelUpModal 
        isOpen={showLevelUpModal} 
        onClose={() => setShowLevelUpModal(false)}
        levelName={newLevelName}
      />
    </div>
  );
};

export default BeerPriceForm;