import React, { useState } from 'react';
import { migrateBeerTypesToFirebase } from '../utils/migrateBeerTypes';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="mb-4">
      <div
        className="flex justify-between items-center border-solid border-2 bg-white-200 p-3 rounded-md cursor-pointer transition duration-300"
        onClick={toggleAnswer}
      >
        <strong>{question}</strong>
        <div
          className={`transform ${isOpen ? 'rotate-90' : 'rotate-0'} transition-transform`}
        >
          🔽
        </div>
      </div>
      {isOpen && (
        <div className="mt-2 bg-slate-100 p-3 rounded-md">
          <p>{answer}</p>
          <div dangerouslySetInnerHTML={{ __html: answer.html }} />
        </div>
      )}
    </div>
  );
};

const FAQs = () => {
  const faqs = [
    {
      question: 'How does it work?',
      answer: "All the pints contributed are crowdsourced, meaning people self report the price when they come across them. Even if they have a website, most pubs do not publish their beer prices online and it can be really hard to find a good cheap pint from a local."
    },
    {
      question: 'How do I know the price is accurate?',
      answer: "The whole idea around this site is pint drinkers sharing amongst pint drinkers the best and cheapest local pubs to go to near you. I can't guarantee that every pint submission is accurate."
    },
    {
        question: 'What about bogus pint reports?',
        answer: "I've done a bunch of work in the form to make sure people can't put fake answers in and to keep the pint prices as accurate as possible. I also manually review and remove one's that fall through the cracks"
      },
      {
        question: 'Do you make money off this?',
        answer: "Hosting this site has started costing me money. I've had to include a couple of ads on here now to try and fund it. If you've found the data on this website useful, please feel free to Buy me a pint ⬇️"
      },
  ];

  const handleMigration = async () => {
    try {
      const success = await migrateBeerTypesToFirebase();
      if (success) {
        alert('Beer types successfully migrated to Firebase!');
      } else {
        alert('Migration failed. Check console for details.');
      }
    } catch (error) {
      console.error('Migration error:', error);
      alert('Migration failed. Check console for details.');
    }
  };

  return (
    <div className="container mx-auto max-w-4xl p-4 py-16 dark:bg-gray-900 h-screen">
      <h1 className="text-2xl font-bold mb-6 dark:text-white">Frequently Asked Questions</h1>
      <ul className="space-y-4">
        {faqs.map((faq, index) => (
          <li key={index} className="bg-white p-4 rounded-lg shadow dark:bg-gray-800">
            <h2 className="text-xl font-semibold mb-2 dark:text-white">{faq.question}</h2>
            <p className="text-gray-700 dark:text-gray-300">{faq.answer}</p>
          </li>
        ))}
      </ul>
      <div className="mt-8">
        <button
          onClick={handleMigration}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Migrate Beer Types to Firebase
        </button>
      </div>
    </div>
  );
};

export default FAQs;
