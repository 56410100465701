import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { PintProvider } from './utils/PintContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppContent from './AppContent';
import { ThemeProvider } from './utils/ThemeContext';
const App = () => {
  return (
    <ThemeProvider>
    <PintProvider>
      <Router>
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
              <AppContent />
      </Router>
    </PintProvider>
    </ThemeProvider>
  );
};

export default App;