import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getGuinnessRatingLabel } from '../utils/guinnessRating';

const PricesTable = ({ prices }) => {
  const [visibleCards, setVisibleCards] = useState(10);

  const sortedPrices = Array.isArray(prices) 
    ? [...prices].sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    : [];

  const getRelativeTime = (timestamp) => {
    const currentTime = new Date();
    const entryTime = new Date(timestamp);
    const timeDifference = currentTime.getTime() - entryTime.getTime();
    const secondsDifference = Math.floor(timeDifference / 1000);

    if (secondsDifference < 60) {
      return 'Just now';
    } else if (secondsDifference < 3600) {
      const minutes = Math.floor(secondsDifference / 60);
      return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (secondsDifference < 86400) {
      const hours = Math.floor(secondsDifference / 3600);
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const days = Math.floor(secondsDifference / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }
  };

  const colorClasses = {
    green: 'bg-green-500',
    orange: 'bg-orange-500',
    red: 'bg-red-500',
  };

  const handleLoadMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 10);
  };

  return (
    <div className="p-6 rounded-lg border bg-slate-50 dark:bg-gray-800 dark:text-white dark:border-gray-700">
      <p className="text-xl mb-1 font-bold">Recent pint contributions</p>
      <p className="text-xs font-bold mb-4 text-slate-700 dark:text-gray-300">Total pints: {sortedPrices.length}</p>
      {sortedPrices.slice(0, visibleCards).map((price, index) => {
        let lineColor;
        if (price.price < 6) {
          lineColor = colorClasses.green;
        } else if (price.price >= 6 && price.price <= 7) {
          lineColor = colorClasses.orange;
        } else {
          lineColor = colorClasses.red;
        }

        const pubName = price.pub || 'Unknown Pub';
        const town = price.town ? `, ${price.town}` : '';
        const postcode = price.postcode ? `, ${price.postcode}` : '';
        const locationDetails = `${pubName}${town}${postcode}`;

        return (
          <div key={index} className="bg-white rounded-lg border border-gray-200 p-4 mb-2 relative dark:bg-slate-700 dark:text-white dark:border-gray-700">
            <div className={`absolute top-0 left-0 right-0 h-1 rounded ${lineColor}`}></div>
            <Link to={`/pub/${price.pub.replace(/\s+/g, '-').toLowerCase()}`} className="font-bold text-sm text-green-600 underline dark:text-white">
              {locationDetails}
            </Link>
            <p className="text-md text-gray-600 dark:text-gray-200">{price.beerType || 'Unknown Beer Type'}</p>
            <p className="font-bold text-2xl">
              £{price.price.toFixed(2)}
              {price.happyHour && (
                <span className="align-middle bg-yellow-300 text-yellow-800 text-sm font-medium px-2.5 py-0.5 rounded dark:bg-yellow-300 dark:text-yellow-900 mb-2 ml-2">
                  Happy Hour
                </span>
              )}
              {price.guinnessRating && price.beerType.toLowerCase() === 'guinness' && ( 
                <span className="align-middle bg-gray-100 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full ml-2 dark:bg-gray-600 dark:text-gray-200">
                  {getGuinnessRatingLabel(price.guinnessRating)}
                </span>
              )}
            </p>
            <small className="text-gray-500 dark:text-gray-400">{getRelativeTime(price.timestamp)}</small>
            <Link to={`/pub/${price.pub.replace(/\s+/g, '-').toLowerCase()}`} className="text-green-600 text-sm font-bold underline mt-2 block dark:text-white">
              Pub details
            </Link>
          </div>
        );
      })}
      {visibleCards < prices.length && (
        <button onClick={handleLoadMore} className="mt-4 text-green-600 font-semibold underline dark:text-green-500">
          Load More
        </button>
      )}
    </div>
  );
};

export default PricesTable;