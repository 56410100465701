import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, query, orderByChild, startAt, endAt, get } from 'firebase/database';
import { firebaseConfig } from './firebaseConfig';

const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);

export const addManualPub = async (pubData) => {
  console.log('ManualPubHandle: Starting addManualPub with data:', pubData); // New log
  
  try {
    const pubEntry = {
      pub: pubData.pub,
      roadName: pubData.roadName,
      town: pubData.town,
      postcode: pubData.postcode,
      source: 'manual',
      verified: false,
      lat: null,
      lng: null,
      timestamp: new Date().toISOString(),
      pints: []
    };

    console.log('ManualPubHandle: Attempting to write to Firebase:', {
      path: `manualPubs/${pubData.id}`,
      data: pubEntry
    }); // New log

    await set(ref(database, `manualPubs/${pubData.id}`), pubEntry);
    console.log('ManualPubHandle: Successfully wrote to Firebase'); // New log
    
    return true;
  } catch (error) {
    console.error('ManualPubHandle: Error adding pub:', error); // Enhanced error log
    return false;
  }
};

export const searchManualPubs = async (searchTerm) => {
  try {
    const manualPubsRef = ref(database, 'manualPubs');
    const pubQuery = query(
      manualPubsRef,
      orderByChild('pub'),
      startAt(searchTerm.toLowerCase()),
      endAt(searchTerm.toLowerCase() + '\uf8ff')
    );
    
    const snapshot = await get(pubQuery);
    const pubs = [];
    
    snapshot.forEach((child) => {
      pubs.push({
        ...child.val(),
        source: 'manual'
      });
    });
    
    return pubs;
  } catch (error) {
    console.error('Error searching manual pubs:', error);
    return [];
  }
};
