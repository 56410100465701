import { getDatabase, ref, set } from 'firebase/database';
import beerTypes from './beerTypes';

export const migrateBeerTypesToFirebase = async () => {
  try {
    const database = getDatabase();
    const beerTypesRef = ref(database, 'beerTypes');

    // Convert array to object with formatted data
    const beerTypesData = beerTypes.reduce((acc, beer) => {
      const id = beer.value.toLowerCase().replace(/[^a-z0-9]/g, '-');
      acc[id] = {
        value: beer.value,
        label: beer.label,
        count: 0,
        timestamp: new Date().toISOString()
      };
      return acc;
    }, {});

    await set(beerTypesRef, beerTypesData);
    console.log('Beer types successfully migrated to Firebase');
    return true;
  } catch (error) {
    console.error('Error migrating beer types:', error);
    return false;
  }
}; 