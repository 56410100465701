import React, { useState, useEffect } from "react";
import Downshift from "downshift";
import { searchPubs } from '../utils/pubLookupService';
import AddPubForm from './AddPubForm';
import { addManualPub } from '../utils/ManualPubHandle';
import { getRecentPubs } from '../utils/recentPubsService';

const PubsSelect = ({ selectedPub, onChange }) => {
  const [pubOptions, setPubOptions] = useState([]);
  const [recentPubs, setRecentPubs] = useState([]);
  const [userLocation, setUserLocation] = useState(null);
  const [locationPermissionGranted, setLocationPermissionGranted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddPubForm, setShowAddPubForm] = useState(false);
  const [lastSearchQuery, setLastSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLocationPermissionGranted(true);
        },
        (error) => {
          setLocationPermissionGranted(false);
        }
      );
    } else {
      setLocationPermissionGranted(false);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'reportedPintDetails' || e.key === 'recentPubs') {
        loadRecentPubs();
      }
    };

    const loadRecentPubs = async () => {
      const recent = await getRecentPubs();
      setRecentPubs(recent);
    };

    // Load initial data
    loadRecentPubs();

    // Listen for changes
    window.addEventListener('storage', handleStorageChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const fetchDefaultPubs = async (latitude, longitude) => {
    if (!locationPermissionGranted) return;
    
    setIsLoading(true);
    try {
      const results = await searchPubs("pub", { latitude, longitude }, true);
      setPubOptions(results);
    } catch (error) {
      console.error("Error fetching default pubs:", error);
      setPubOptions([]);
    } finally {
      setIsLoading(false);
    }
  };
  
  const sortByProximity = (pubs) => {
    if (!userLocation) return pubs;
    
    return [...pubs].sort((a, b) => {
      if (!a.lat || !b.lat) return 0;
      const distA = getDistance(userLocation, { lat: a.lat, lng: a.lng });
      const distB = getDistance(userLocation, { lat: b.lat, lng: b.lng });
      return distA - distB;
    });
  };

  const highlightMatch = (text, query) => {
    if (!query || !text) return text;
    try {
      const regex = new RegExp(`(${query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'gi');
      const parts = text.toString().split(regex);
      return (
        <>
          {parts.map((part, i) => 
            regex.test(part) ? 
              <mark key={i} className="bg-transparent font-bold text-green-600 dark:text-green-400">{part}</mark> : 
              part
          )}
        </>
      );
    } catch (error) {
      return text;
    }
  };

  const handleSearch = async (inputValue) => {
    setSearchTerm(inputValue);
    setLastSearchQuery(inputValue);
    if (!inputValue) {
      setPubOptions([]);
      return;
    }

    let isMounted = true;
    setIsLoading(true);
    
    try {
      const results = await searchPubs(inputValue, userLocation, false);
      if (isMounted) {
        setPubOptions(sortByProximity(results));
      }
    } catch (error) {
      if (isMounted) {
        console.error("Error searching pubs:", error);
        setPubOptions([]);
      }
    } finally {
      if (isMounted) {
        setIsLoading(false);
      }
    }
  };

  return (
    <Downshift
      selectedItem={selectedPub}
      onChange={onChange}
      inputValue={searchTerm}
      onInputValueChange={(value) => setSearchTerm(value)}
      itemToString={(item) => 
        item ? 
          `${item.pubName}${item.roadName ? `, ${item.roadName}` : ''}${item.town ? `, ${item.town}` : ''}` 
          : ''
      }
    >
      {({
        getInputProps,
        getMenuProps,
        getItemProps,
        highlightedIndex,
        isOpen,
        selectedItem,
        openMenu,
      }) => (
        <div className="relative">
          <div className="relative">
            <input
              {...getInputProps({
                placeholder: locationPermissionGranted 
                  ? "Select to see nearby or start typing..." 
                  : "Start typing to search pubs...",
                onFocus: () => {
                  if (userLocation) {
                    fetchDefaultPubs(userLocation.latitude, userLocation.longitude);
                  }
                  openMenu();
                },
                onChange: (e) => handleSearch(e.target.value),
                className: "w-full border border-gray-300 dark:border-gray-600 rounded px-3 py-2 text-lg dark:bg-gray-800 dark:text-white"
              })}
            />
            {isLoading && (
              <div className="absolute right-2 top-2">
                <div className="animate-spin h-5 w-5 border-2 border-green-500 dark:border-green-400 rounded-full border-t-transparent"></div>
              </div>
            )}
          </div>

          <div
            {...getMenuProps()}
            className={`absolute z-10 mt-1 w-full ${
              isOpen && (pubOptions.length > 0 || lastSearchQuery) ? "block" : "hidden"
            } bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg shadow-lg max-h-[400px] overflow-auto`}
          >
            {pubOptions.map((item, index) => (
              <div
                {...getItemProps({
                  key: item.value || index,
                  index,
                  item,
                  disabled: item.isLabel,
                })}
                className={`${
                  highlightedIndex === index ? "bg-blue-50 dark:bg-blue-800" : ""
                } ${
                  item.isLabel ? "bg-gray-100 dark:bg-gray-800 font-semibold" : "hover:bg-blue-50 dark:hover:bg-blue-800"
                } py-3 px-4 cursor-pointer transition-colors duration-150`}
              >
                {item.isLabel ? (
                  <span className="text-sm text-gray-600 dark:text-gray-400">{item.label}</span>
                ) : (
                  <div>
                    <div className="font-medium text-lg dark:text-white">
                      {typeof item.pubName === 'string' ? highlightMatch(item.pubName, searchTerm) : item.pubName}
                    </div>
                    {(item.roadName || item.town) && (
                      <div className="text-sm text-gray-600 dark:text-gray-400">
                        {typeof item.roadName === 'string' && highlightMatch(item.roadName, searchTerm)}
                        {item.town && typeof item.town === 'string' && (
                          <>
                            {item.roadName && ', '}
                            {highlightMatch(item.town, searchTerm)}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
            
            {lastSearchQuery && !isLoading && (
              <div className="border-t dark:border-gray-700">
                <button
                  onClick={() => setShowAddPubForm(true)}
                  className="w-full text-left py-3 px-3 text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-500 hover:bg-green-50 dark:hover:bg-green-900 font-medium"
                >
                  + Add "{lastSearchQuery}" as a new pub
                </button>
              </div>
            )}
          </div>

          {isOpen && pubOptions.length === 0 && lastSearchQuery && !isLoading && (
            <div className="absolute z-10 mt-2 w-full bg-white dark:bg-gray-900 border dark:border-gray-700 rounded shadow-md p-4">
              <p className="text-gray-600 dark:text-gray-400 mb-2">No pubs found matching "{lastSearchQuery}"</p>
              <button
                onClick={() => setShowAddPubForm(true)}
                className="text-green-600 dark:text-green-400 hover:text-green-700 dark:hover:text-green-500 font-medium"
              >
                + Add this pub
              </button>
            </div>
          )}

          {showAddPubForm && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
              <AddPubForm
                searchQuery={lastSearchQuery}
                onClose={() => setShowAddPubForm(false)}
                onSuccess={(newPub) => {
                  onChange(newPub);
                  setShowAddPubForm(false);
                  setPubOptions([]);
                  setLastSearchQuery('');
                }}
              />
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
};

// Helper function to calculate distance between two points
const getDistance = (point1, point2) => {
  const R = 6371; // Earth's radius in km
  const dLat = (point2.lat - point1.latitude) * Math.PI / 180;
  const dLon = (point2.lng - point1.longitude) * Math.PI / 180;
  const a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(point1.latitude * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180) * 
    Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c;
};

export default PubsSelect;